import React, { useCallback, useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import Layout from '../components/Layout';
import ReputationScoreLabel from '../components/ReputationScore/ReputationScoreLabel';
import Pagination from '../components/Pagination';
import SiteLink from '../components/SiteLink';
import Breadcrumbs from '../components/Breadcrumbs';

import {
  formatReputationScore,
  formatRichText,
  getMovement,
} from '../utils/formatting';

type Company = {
  displayName: string;
  score: number;
  previousRank?: number;
  icon?: string;
};

type Grt100ListPageProps = {
  pageContext: {
    displayName: string;
    list: [Company];
    description: string;
    methodology: string;
    reportLink?: {
      url: string;
      label: string;
    };
  };
};

const Grt100ListPage = ({
  pageContext: { list, description, methodology, displayName, reportLink = {} },
}: Grt100ListPageProps): JSX.Element => {
  /* Pagination */
  const pageLength = 10;
  const listLength = list.length;
  const [activePage, setActivePage] = useState(1);

  const getActiveList = useCallback(() => {
    const firstIndex = (activePage - 1) * pageLength;
    const lastIndex = firstIndex + pageLength;
    return list.slice(firstIndex, lastIndex);
  }, [activePage]);
  const activeList = getActiveList();

  const formattingOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="pb-4">{children}</p>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc pb-4 mx-4">{children}</ul>
      ),
    },
  };

  return (
    <Layout title={displayName} description={description}>
      <div className="w-100 p-4 max-w-4xl mx-auto">
        <Breadcrumbs
          className="mb-6"
          links={[
            <SiteLink href="https://www.reptrak.com/" isExternal>
              Home
            </SiteLink>,
          ]}
          activePage="Rankings"
        />
        <h1 className="font-semibold text-3xl text-gray-900">{displayName}</h1>
        <p className="mb-8 text-gray-900">
          {methodology && formatRichText(methodology?.raw, formattingOptions)}
        </p>

        {reportLink?.url && reportLink.label && (
          <SiteLink
            href={reportLink.url}
            isExternal
            className="inline-block text-center bg-rt-anchor-blue text-white p-4 text-xl font-bold w-full md:w-auto rounded border-2 border-rt-anchor-blue focus:bg-white hover:bg-white focus:text-rt-anchor-blue hover:text-rt-anchor-blue mt-8 mb-20"
          >
            {reportLink.label}
          </SiteLink>
        )}

        <h2 className="font-semibold text-2xl text-gray-900">
          The Global RepTrak&trade;100
        </h2>
        <p className="mb-8 text-gray-900">{description}</p>

        <div>
          <ul className="divide-y divide-rt-grey-smoke w-100 p-0">
            {activeList.map((company) => (
              <li key={`${company.rank}-rank`}>
                <SiteLink
                  href={`/company/${company.slug}`}
                  className="flex items-center py-2 rt-anchor-blue hover:bg-rt-white-smoke focus:bg-rt-white-smoke"
                >
                  <div className="w-1/12 px-4 text-lg md:text-6xl text-rt-steely-grey text-left">
                    {company.rank}
                  </div>
                  <div className="w-1/12 pr-2">
                    {company.previousRank &&
                      getMovement(company.previousRank.position, company.rank)}
                  </div>
                  <div className="w-2/12 sm:w-2/12 p-0 sm:py-4">
                    <img
                      src={company.icon?.file?.url}
                      alt=""
                      className="max-h-28"
                    />
                  </div>
                  <div className="w-5/12 sm:w-6/12 px-2 text-sm md:text-xl ">
                    {company.displayName}
                  </div>
                  <div className="w-3/12 sm:w-2/12 text-center pl-2 pr-4 py-4">
                    <div className="text-2xl md:text-5xl text-rt-steely-grey">
                      {formatReputationScore(company.score)}
                    </div>
                    <div>
                      <ReputationScoreLabel
                        score={company.score}
                        className="text-xs md:text-sm py-0"
                      />
                    </div>
                  </div>
                </SiteLink>
              </li>
            ))}
          </ul>

          <Pagination
            className="my-16 md:my-32 max-w-md mx-auto"
            pageLength={10}
            listLength={listLength}
            handlePageUpdate={setActivePage}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Grt100ListPage;
