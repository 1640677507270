import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useQueryParam, NumberParam } from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

type PaginationProps = {
  pageLength: number;
  listLength: number;
  handlePageUpdate: (pageNumber: number) => void;
  className: string;
};

const Pagination = ({
  pageLength,
  listLength,
  handlePageUpdate,
  className = '',
}: PaginationProps): JSX.Element => {
  const [activePage, setActivePage] = useState(1);
  const [backButtonActive, setBackButtonActive] = useState(false);
  const [forwardButtonActive, setForwardButtonActive] = useState(true);
  const [pageQueryString, setPageQueryString] = useQueryParam(
    'page',
    NumberParam
  );
  const pageCount = Math.ceil(listLength / pageLength);
  const numberButtons = Array.from(Array(pageCount).keys());

  const handleSetActivePage = (pageNumber) => {
    setBackButtonActive(pageNumber !== 1);
    setForwardButtonActive(pageNumber !== pageCount);
    setActivePage(pageNumber);
    setPageQueryString(pageNumber);
    return handlePageUpdate(pageNumber);
  };

  // On page load check for a pagination query string in the url more
  useEffect(() => {
    if (pageQueryString) {
      handleSetActivePage(pageQueryString);
    }
  }, []);

  const handlePaginationBack = () => handleSetActivePage(activePage - 1);

  const handlePaginationForward = () => handleSetActivePage(activePage + 1);

  const handleNumberButtonClick = (pageNumber) =>
    handleSetActivePage(pageNumber);

  const mainButtonClassList =
    'h-9 m:w-9 py-4 px-2 text-rt-grey-smoke disabled:text-blue-300 flex items-center focus:text-black hover:text-black';

  return (
    <div
      className={clsx(
        'flex',
        'items-center',
        'justify-between',
        'w-100',
        className
      )}
    >
      {backButtonActive ? (
        <button
          type="button"
          onClick={handlePaginationBack}
          className={mainButtonClassList}
        >
          <FontAwesomeIcon icon={faAngleLeft} size="2x" />
          <span className="md:sr-only ml-4">Back</span>
        </button>
      ) : (
        <div className="md:w-8" />
      )}

      {numberButtons.map((number) => (
        <button
          type="button"
          key={`pagination-button-${number}`}
          className={clsx(
            'hidden',
            'md:inline',
            'h-8',
            'w-8',
            'text-sm',
            'border-2',
            'border-rt-grey-smoke',
            'hover:bg-white',
            'hover:text-rt-grey-smoke',
            { 'bg-rt-grey-smoke text-white': activePage !== number + 1 },
            { 'bg-white text-rt-grey-smoke': activePage === number + 1 }
          )}
          onClick={() => handleNumberButtonClick(number + 1)}
        >
          {number + 1}
        </button>
      ))}

      {forwardButtonActive ? (
        <button
          type="button"
          onClick={handlePaginationForward}
          className={mainButtonClassList}
        >
          <span className="md:sr-only mr-4">Next</span>
          <FontAwesomeIcon icon={faAngleRight} size="2x" />
        </button>
      ) : (
        <div className="md:w-8" />
      )}
    </div>
  );
};

export default Pagination;
